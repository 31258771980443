import { Link } from "gatsby"
import React from "react"
import Layout from '../components/layout';
import { graphql } from 'gatsby';

const AllCategoriesPage = ({data}) => {
	const seoData = {
		'title' : 'All categories and subcategories page', 
		'description' : 'All categories and subcategories page', 
		'canonicalUrl' : '/all-categories/', 
		'robots' : 'index,follow'
		};
	
	return(
  <Layout page={seoData} >
		<section className="container all-list-wrapper">
    <h1>All categories and sub-categories</h1>
		<div className="all-categories-wrapper">
	{data.hc.categories.map(category => (
			<div className="all-categories-list">
				<h2 key={category.id}> <Link to={category.page.url}>{category.name}</Link></h2>
				<ul>
				{category.subCategories.map(subCategory => (
				  <li key={subCategory.id}>
					<Link to={subCategory.page.url}>{subCategory.name}</Link>
				  </li>
				))}
				</ul>
			</div>
	))}
	</div>
	</section>
  </Layout>
)
}

export default AllCategoriesPage

export const allCategories = graphql`
  query {
    hc {
      categories {
        id
        name
		page {url gatsbyUrl}
        subCategories{id name page {url gatsbyUrl}}
      }
    }
  }
`;
